import { IAirwebTicketLegacyNetworkConfiguration } from '@airweb/ticket-legacy-client';
import { Form, Question } from '@/types/form';

export const useNetwork = defineStore('network', {
  state: () => ({
    config: {} as IAirwebTicketLegacyNetworkConfiguration,
    formConfig: {} as Form,
  }),

  getters: {
    // We transform lang type like fr_FR to fr
    languageCodeList(state) {
      return state.config.languages?.map((language) => isoToCode(language)) ?? [];
    },
    enableHelpPage(state) {
      try {
        const config = useRuntimeConfig();
        const env = config.public.env !== 'production' ? 'staging' : 'production';

        const hasHelpURLConfigued = Boolean(config.public.helpPageUrl);
        if (!hasHelpURLConfigued) return false;

        return config.public.helpPageEnabledFor[env].includes(parseInt(state.config.id));
      } catch {
        return false;
      }
    },
    questionList: (state): Question[] => {
      return state.formConfig.pages.reduce<Question[]>(
        (acc, page) => [...acc, ...page.questions],
        [],
      );
    },
  },
});
