<script setup lang="ts">
import { useAuth } from '@/store/auth';

const auth = useAuth();
const router = useRouter();
const localePath = useLocalePath();

const cancelLoader = ref<boolean>(false);
const confirmLoader = ref<boolean>(false);
const showErrors = ref<boolean>(false);

async function logout() {
  try {
    showErrors.value = false;
    cancelLoader.value = true;

    await auth.logout();
    router.push(localePath('index'));
  } catch {
    showErrors.value = true;
  } finally {
    cancelLoader.value = false;
  }
}

async function reactivateAccount() {
  try {
    showErrors.value = false;
    confirmLoader.value = true;

    await auth.reactivateAccount();
  } catch {
    showErrors.value = true;
  } finally {
    confirmLoader.value = false;
  }
}
</script>

<template>
  <AppModal
    v-if="auth.user && auth.user.userAnonymizationRequestedAt"
    title="Vous allez réativer votre compte"
    :warning="true"
  >
    <template #content>
      <AppAlert v-if="showErrors" status="DANGER" class="mb-4">
        {{ $t('otherslabels.common_error') }}
      </AppAlert>

      <p class="whitespace-pre-wrap">{{ $t('pages.account_reactivation_modal.text') }}</p>
    </template>

    <template #footer>
      <div
        class="flex flex-col items-center justify-end space-y-4 md:flex-row md:space-x-4 md:space-y-0"
      >
        <AppButton state="base" @click="logout">
          <AppLoader v-if="cancelLoader" class="mr-3 h-5" />

          <span>{{ $t('otherslabels.cancel') }}</span>
        </AppButton>

        <AppButton state="primary" @click="reactivateAccount">
          <AppLoader v-if="confirmLoader" class="mr-3 h-5" />

          <span>{{ $t('otherslabels.continue') }}</span>
        </AppButton>
      </div>
    </template>
  </AppModal>
</template>
