import {
  Flag,
  FrontService,
  UpkeepFrontendClient,
  type MaintenanceStatus,
} from '@airweb/upkeep-frontend-client';

export const useUpKeep = defineStore('upKeep', {
  state: () => ({
    alerts: [] as MaintenanceStatus[],
    alertsLoaded: false,
  }),
  getters: {
    globalAlert(state): MaintenanceStatus | null {
      return UpkeepFrontendClient.findGlobalAlert(state.alerts);
    },

    orderAlert(state): MaintenanceStatus | null {
      return UpkeepFrontendClient.findFlagAlert(state.alerts, Flag.ORDER);
    },
  },
  actions: {
    async getAvailableMaintenanceAlerts(networkId: number): Promise<MaintenanceStatus[]> {
      const nuxtApp = useNuxtApp();
      this.alertsLoaded = false;

      // Retrieve alerts from upKeep;
      const alerts = await nuxtApp.$upKeep.getNetworkMaintenanceAlerts(networkId, [
        FrontService.SCHOOL_ESHOP,
      ]);

      this.alerts = alerts;
      this.alertsLoaded = true;
      return alerts;
    },
  },
});
