export function isoToCode(iso: string): string {
  const nuxtApp = useNuxtApp();

  for (const lang of nuxtApp.$i18n.locales.value) {
    if (typeof lang === 'string') {
      if (lang === iso) {
        return lang;
      }

      continue;
    }

    if (lang.iso === iso) {
      return lang.code;
    }
  }

  return '';
}
