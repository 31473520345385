import { default as deleteGnDOesUGIEMeta } from "/app/pages/account/delete.vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as invoicestmq8neHAodMeta } from "/app/pages/account/invoices.vue?macro=true";
import { default as payment_45cardsRnR9jGJ0cbMeta } from "/app/pages/account/payment-cards.vue?macro=true";
import { default as accountIWnKuvcAuMMeta } from "/app/pages/account.vue?macro=true";
import { default as indexaVPDnhf1OvMeta } from "/app/pages/auth/index.vue?macro=true";
import { default as accountgZwXH2t970Meta } from "/app/pages/cart/account.vue?macro=true";
import { default as checkout4YA79jVi1xMeta } from "/app/pages/cart/checkout.vue?macro=true";
import { default as deliverywEtTBtwXGCMeta } from "/app/pages/cart/delivery.vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as cart9SktLKPpbzMeta } from "/app/pages/cart.vue?macro=true";
import { default as clear0ItD2BFdyuMeta } from "/app/pages/clear.vue?macro=true";
import { default as infoKQcaUvIdSzMeta } from "/app/pages/delete/info.vue?macro=true";
import { default as successM10n7WF9beMeta } from "/app/pages/delete/success.vue?macro=true";
import { default as _91id_93pAQXQg0sNXMeta } from "/app/pages/duplicate/[id].vue?macro=true";
import { default as productsDYGVPbswcZMeta } from "/app/pages/duplicate/products.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as legalsl9w9Er0CCLMeta } from "/app/pages/legals.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as _91id_93m5hwe6XdulMeta } from "/app/pages/orders/[id].vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as _91id_930vg4Yd6NyQMeta } from "/app/pages/profiles/[id].vue?macro=true";
import { default as indexBqiuZpk0bsMeta } from "/app/pages/profiles/index.vue?macro=true";
import { default as indexhOBcdtdQ4pMeta } from "/app/pages/register/index.vue?macro=true";
import { default as personalpG2OpCiAMqMeta } from "/app/pages/register/personal.vue?macro=true";
import { default as products0OgH2noNHPMeta } from "/app/pages/register/products.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as indexwfiOBUrUy9Meta } from "/app/pages/renew/index.vue?macro=true";
import { default as personaloVE68LnQpoMeta } from "/app/pages/renew/personal.vue?macro=true";
import { default as products2MpwGFqUW9Meta } from "/app/pages/renew/products.vue?macro=true";
import { default as renewoZHCwPTQaYMeta } from "/app/pages/renew.vue?macro=true";
import { default as unverifiedCxsaUjVRgbMeta } from "/app/pages/unverified.vue?macro=true";
import { default as verification4hxpuJdPABMeta } from "/app/pages/verification.vue?macro=true";
export default [
  {
    name: accountIWnKuvcAuMMeta?.name,
    path: "/account",
    meta: accountIWnKuvcAuMMeta || {},
    component: () => import("/app/pages/account.vue"),
    children: [
  {
    name: "account-delete___fr",
    path: "delete",
    component: () => import("/app/pages/account/delete.vue")
  },
  {
    name: "account___fr",
    path: "",
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account-invoices___fr",
    path: "invoices",
    component: () => import("/app/pages/account/invoices.vue")
  },
  {
    name: "account-payment-cards___fr",
    path: "payment-cards",
    component: () => import("/app/pages/account/payment-cards.vue")
  }
]
  },
  {
    name: accountIWnKuvcAuMMeta?.name,
    path: "/en/account",
    meta: accountIWnKuvcAuMMeta || {},
    component: () => import("/app/pages/account.vue"),
    children: [
  {
    name: "account-delete___en",
    path: "delete",
    component: () => import("/app/pages/account/delete.vue")
  },
  {
    name: "account___en",
    path: "",
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account-invoices___en",
    path: "invoices",
    component: () => import("/app/pages/account/invoices.vue")
  },
  {
    name: "account-payment-cards___en",
    path: "payment-cards",
    component: () => import("/app/pages/account/payment-cards.vue")
  }
]
  },
  {
    name: "auth___fr",
    path: "/auth",
    component: () => import("/app/pages/auth/index.vue")
  },
  {
    name: "auth___en",
    path: "/en/auth",
    component: () => import("/app/pages/auth/index.vue")
  },
  {
    name: cart9SktLKPpbzMeta?.name,
    path: "/cart",
    component: () => import("/app/pages/cart.vue"),
    children: [
  {
    name: "cart-account___fr",
    path: "account",
    meta: accountgZwXH2t970Meta || {},
    component: () => import("/app/pages/cart/account.vue")
  },
  {
    name: "cart-checkout___fr",
    path: "checkout",
    meta: checkout4YA79jVi1xMeta || {},
    component: () => import("/app/pages/cart/checkout.vue")
  },
  {
    name: "cart-delivery___fr",
    path: "delivery",
    meta: deliverywEtTBtwXGCMeta || {},
    component: () => import("/app/pages/cart/delivery.vue")
  },
  {
    name: "cart___fr",
    path: "",
    component: () => import("/app/pages/cart/index.vue")
  }
]
  },
  {
    name: cart9SktLKPpbzMeta?.name,
    path: "/en/cart",
    component: () => import("/app/pages/cart.vue"),
    children: [
  {
    name: "cart-account___en",
    path: "account",
    meta: accountgZwXH2t970Meta || {},
    component: () => import("/app/pages/cart/account.vue")
  },
  {
    name: "cart-checkout___en",
    path: "checkout",
    meta: checkout4YA79jVi1xMeta || {},
    component: () => import("/app/pages/cart/checkout.vue")
  },
  {
    name: "cart-delivery___en",
    path: "delivery",
    meta: deliverywEtTBtwXGCMeta || {},
    component: () => import("/app/pages/cart/delivery.vue")
  },
  {
    name: "cart___en",
    path: "",
    component: () => import("/app/pages/cart/index.vue")
  }
]
  },
  {
    name: "clear___fr",
    path: "/clear",
    component: () => import("/app/pages/clear.vue")
  },
  {
    name: "clear___en",
    path: "/en/clear",
    component: () => import("/app/pages/clear.vue")
  },
  {
    name: "delete-info___fr",
    path: "/delete/info",
    component: () => import("/app/pages/delete/info.vue")
  },
  {
    name: "delete-info___en",
    path: "/en/delete/info",
    component: () => import("/app/pages/delete/info.vue")
  },
  {
    name: "delete-success___fr",
    path: "/delete/success",
    component: () => import("/app/pages/delete/success.vue")
  },
  {
    name: "delete-success___en",
    path: "/en/delete/success",
    component: () => import("/app/pages/delete/success.vue")
  },
  {
    name: "duplicate-id___fr",
    path: "/duplicate/:id()",
    meta: _91id_93pAQXQg0sNXMeta || {},
    component: () => import("/app/pages/duplicate/[id].vue")
  },
  {
    name: "duplicate-id___en",
    path: "/en/duplicate/:id()",
    meta: _91id_93pAQXQg0sNXMeta || {},
    component: () => import("/app/pages/duplicate/[id].vue")
  },
  {
    name: "duplicate-products___fr",
    path: "/duplicate/products",
    meta: productsDYGVPbswcZMeta || {},
    component: () => import("/app/pages/duplicate/products.vue")
  },
  {
    name: "duplicate-products___en",
    path: "/en/duplicate/products",
    meta: productsDYGVPbswcZMeta || {},
    component: () => import("/app/pages/duplicate/products.vue")
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "legals___fr",
    path: "/legals",
    component: () => import("/app/pages/legals.vue")
  },
  {
    name: "legals___en",
    path: "/en/legals",
    component: () => import("/app/pages/legals.vue")
  },
  {
    name: "maintenance___fr",
    path: "/maintenance",
    component: () => import("/app/pages/maintenance.vue")
  },
  {
    name: "maintenance___en",
    path: "/en/maintenance",
    component: () => import("/app/pages/maintenance.vue")
  },
  {
    name: "orders-id___fr",
    path: "/orders/:id()",
    meta: _91id_93m5hwe6XdulMeta || {},
    component: () => import("/app/pages/orders/[id].vue")
  },
  {
    name: "orders-id___en",
    path: "/en/orders/:id()",
    meta: _91id_93m5hwe6XdulMeta || {},
    component: () => import("/app/pages/orders/[id].vue")
  },
  {
    name: "orders___fr",
    path: "/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "orders___en",
    path: "/en/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "profiles-id___fr",
    path: "/profiles/:id()",
    meta: _91id_930vg4Yd6NyQMeta || {},
    component: () => import("/app/pages/profiles/[id].vue")
  },
  {
    name: "profiles-id___en",
    path: "/en/profiles/:id()",
    meta: _91id_930vg4Yd6NyQMeta || {},
    component: () => import("/app/pages/profiles/[id].vue")
  },
  {
    name: "profiles___fr",
    path: "/profiles",
    meta: indexBqiuZpk0bsMeta || {},
    component: () => import("/app/pages/profiles/index.vue")
  },
  {
    name: "profiles___en",
    path: "/en/profiles",
    meta: indexBqiuZpk0bsMeta || {},
    component: () => import("/app/pages/profiles/index.vue")
  },
  {
    name: registermu8R7rSP5uMeta?.name,
    path: "/register",
    component: () => import("/app/pages/register.vue"),
    children: [
  {
    name: "register___fr",
    path: "",
    component: () => import("/app/pages/register/index.vue")
  },
  {
    name: "register-personal___fr",
    path: "personal",
    component: () => import("/app/pages/register/personal.vue")
  },
  {
    name: "register-products___fr",
    path: "products",
    component: () => import("/app/pages/register/products.vue")
  }
]
  },
  {
    name: registermu8R7rSP5uMeta?.name,
    path: "/en/register",
    component: () => import("/app/pages/register.vue"),
    children: [
  {
    name: "register___en",
    path: "",
    component: () => import("/app/pages/register/index.vue")
  },
  {
    name: "register-personal___en",
    path: "personal",
    component: () => import("/app/pages/register/personal.vue")
  },
  {
    name: "register-products___en",
    path: "products",
    component: () => import("/app/pages/register/products.vue")
  }
]
  },
  {
    name: renewoZHCwPTQaYMeta?.name,
    path: "/renew",
    component: () => import("/app/pages/renew.vue"),
    children: [
  {
    name: "renew___fr",
    path: "",
    meta: indexwfiOBUrUy9Meta || {},
    component: () => import("/app/pages/renew/index.vue")
  },
  {
    name: "renew-personal___fr",
    path: "personal",
    meta: personaloVE68LnQpoMeta || {},
    component: () => import("/app/pages/renew/personal.vue")
  },
  {
    name: "renew-products___fr",
    path: "products",
    meta: products2MpwGFqUW9Meta || {},
    component: () => import("/app/pages/renew/products.vue")
  }
]
  },
  {
    name: renewoZHCwPTQaYMeta?.name,
    path: "/en/renew",
    component: () => import("/app/pages/renew.vue"),
    children: [
  {
    name: "renew___en",
    path: "",
    meta: indexwfiOBUrUy9Meta || {},
    component: () => import("/app/pages/renew/index.vue")
  },
  {
    name: "renew-personal___en",
    path: "personal",
    meta: personaloVE68LnQpoMeta || {},
    component: () => import("/app/pages/renew/personal.vue")
  },
  {
    name: "renew-products___en",
    path: "products",
    meta: products2MpwGFqUW9Meta || {},
    component: () => import("/app/pages/renew/products.vue")
  }
]
  },
  {
    name: "unverified___fr",
    path: "/unverified",
    meta: unverifiedCxsaUjVRgbMeta || {},
    component: () => import("/app/pages/unverified.vue")
  },
  {
    name: "unverified___en",
    path: "/en/unverified",
    meta: unverifiedCxsaUjVRgbMeta || {},
    component: () => import("/app/pages/unverified.vue")
  },
  {
    name: "verification___fr",
    path: "/verification",
    meta: verification4hxpuJdPABMeta || {},
    component: () => import("/app/pages/verification.vue")
  },
  {
    name: "verification___en",
    path: "/en/verification",
    meta: verification4hxpuJdPABMeta || {},
    component: () => import("/app/pages/verification.vue")
  }
]