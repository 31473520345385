import { UpkeepFrontendClient } from '@airweb/upkeep-frontend-client';

export default defineNuxtPlugin((nuxtApp) => {
  const upKeep = new UpkeepFrontendClient(
    nuxtApp.$config.public.maintenanceBeaconUrlList?.split('|'),
    {
      timeout: 1000,
      punishTime: 60000,
    },
  );

  return {
    provide: {
      upKeep,
    },
  };
});
