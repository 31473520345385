<script setup lang="ts">
import { useNetwork } from '@/store/network';

const localePath = useLocalePath();
const nuxtApp = useNuxtApp();
const network = useNetwork();
const showHelpPanel = ref(false);
const globalOverflow = ref(true);

function open(): void {
  globalOverflow.value = false;
  showHelpPanel.value = true;
}

function close(): void {
  globalOverflow.value = true;
  showHelpPanel.value = false;
}

function toggle(): void {
  showHelpPanel.value ? close() : open();
}
</script>

<template>
  <!-- When the sidebar is open we hide the overflow on the html element -->
  <Html :class="[globalOverflow ? '' : 'overflow-hidden']" />

  <AppBackdrop v-if="showHelpPanel" class="absolute z-40" @close="close()" />

  <div
    class="fixed inset-y-0 right-0 z-[999] w-[50vw] overflow-y-auto bg-white p-4 shadow duration-300"
    :class="[showHelpPanel ? 'translate-x-0' : 'translate-x-[50vw]']"
  >
    <h3 class="font-bold">{{ $t('pages.header.technical_difficulty') }}</h3>
    <p class="mt-4">
      {{ $t('pages.help_sidebar.difficulty_description_1') }}
    </p>
    <p class="mt-2">
      {{ $t('pages.help_sidebar.difficulty_description_2') }}
    </p>

    <AppButton :to="localePath('clear')" class="mt-4">{{
      $t('pages.help_sidebar.reset_session')
    }}</AppButton>

    <template v-if="network.enableHelpPage">
      <hr class="my-4" />

      <h3 class="font-bold">{{ $t('pages.help_sidebar.problem_title') }}</h3>

      <p class="mt-2">
        {{ $t('pages.help_sidebar.problem_description') }}
      </p>

      <AppButton
        :href="nuxtApp.$config.public.helpPageUrl"
        rel="noopener"
        target="_blank"
        class="mt-4"
      >
        {{ $t('pages.help_sidebar.schedule_appointment') }}
      </AppButton>
    </template>
  </div>

  <!-- I add a pr-3, beacause the space of the button is fuck-up with the rotate of the image -->
  <AppButton
    state="info"
    class="absolute right-0 top-1/2 z-[999] origin-bottom-right -translate-y-[400%] -rotate-90 whitespace-nowrap pr-3 duration-300"
    :class="{ '-translate-x-[50vw]': showHelpPanel }"
    @click="toggle"
  >
    <span class="flex-1">{{ $t('pages.help_sidebar.blocked') }}</span>
    <img src="/assets/idee.gif" alt="Icône d'une ampoule" class="ml-2 block h-6 w-auto rotate-90" />
  </AppButton>
</template>
