<script setup lang="ts">
import { useAuth } from '@/store/auth';
import { useCart } from '@/store/cart';
import { useNetwork } from '@/store/network';

const auth = useAuth();
const cart = useCart();
const network = useNetwork();
const localePath = useLocalePath();
</script>

<template>
  <header class="relative z-20 text-neutral-800">
    <div
      class="container flex items-center justify-between px-6 sm:px-4"
      :class="{ 'py-3': !auth.isLoggedIn }"
    >
      <NuxtLink :to="auth.isLoggedIn ? localePath('profiles') : localePath('index')">
        <span class="sr-only">{{ $t('pages.header.logo_text') }}</span>
        <img :alt="network.config.name" :src="network.config.logo" class="h-20 w-auto flex-1" />
      </NuxtLink>

      <nav class="flex items-center space-x-6">
        <NuxtLink
          v-if="auth.isLoggedIn"
          :to="localePath('profiles')"
          activeClass="!border-secondary"
          class="group flex flex-col items-end border-t-4 border-transparent py-3 hover:border-secondary sm:py-4"
        >
          <Icon name="mdi:account-multiple-outline" class="h-6 w-6 md:hidden" />
          <span
            class="hidden text-sm font-semibold uppercase text-neutral-700 group-hover:text-blue-600 md:inline-flex"
          >
            {{ $t('pages.header.profiles_btn') }}
          </span>
          <span class="hidden text-xs text-neutral-500 md:inline-flex">
            {{ $t('pages.header.profiles_desc') }}
          </span>
        </NuxtLink>

        <NuxtLink
          v-if="auth.isLoggedIn"
          :to="localePath('orders')"
          activeClass="!border-secondary"
          class="group flex flex-col items-end border-t-4 border-transparent py-3 hover:border-secondary sm:py-4"
        >
          <Icon name="mdi:alert-circle-check-outline" class="h-6 w-6 md:hidden" />
          <span
            class="hidden text-sm font-semibold uppercase text-neutral-700 group-hover:text-blue-600 md:inline-flex"
          >
            {{ $t('pages.header.tracking_btn') }}
          </span>
          <span class="hidden text-xs text-neutral-500 md:inline-flex">
            {{ $t('pages.header.tracking_desc') }}
          </span>
        </NuxtLink>

        <NuxtLink
          v-if="auth.isLoggedIn"
          :to="localePath('account')"
          activeClass="!border-secondary"
          class="group flex flex-col items-end border-t-4 border-transparent py-3 hover:border-secondary sm:py-4"
        >
          <Icon name="mdi:account-outline" class="h-6 w-6 md:hidden" />
          <span class="hidden text-sm font-semibold uppercase text-neutral-700 md:inline-flex">
            {{ auth.fullname }}
          </span>
          <span class="hidden text-xs text-neutral-500 md:inline-flex">
            {{ $t('pages.header.account_desc') }}
          </span>
        </NuxtLink>

        <NuxtLink
          :to="localePath('cart')"
          class="relative inline-flex rounded bg-neutral-800 p-3 text-neutral-100"
        >
          <span class="sr-only">{{ $t('pages.header.cart_btn_alt') }}</span>
          <Icon name="mdi:cart-outline" class="h-6 w-6" />

          <span
            v-if="!cart.isEmpty"
            class="absolute bottom-0 right-0 inline-flex h-5 w-5 translate-x-1/2 translate-y-1/2 transform items-center justify-center rounded-full bg-red-500 text-xs font-semibold text-neutral-100"
          >
            {{ cart.itemsCount }}
          </span>
        </NuxtLink>
      </nav>
    </div>
  </header>
</template>
