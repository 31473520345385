export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject();
      }
    };
    reader.onerror = reject;
  });
}

export async function base64ToFile(
  dataUrl: string,
  fileName: string,
  fileType: string,
): Promise<File> {
  const response: Response = await fetch(dataUrl);
  const blob: Blob = await response.blob();
  return new File([blob], fileName, { type: fileType });
}
