import {
  AirwebTicketLegacyClient,
  IAirwebTicketLegacyUserInfo,
} from '@airweb/ticket-legacy-client';

import { Form } from '@/types/form';
import { sessionCookieConfig, sessionCookieName, useAuth } from '@/store/auth';
import { useNetwork } from '@/store/network';

export default defineNuxtPlugin(async (nuxtApp) => {
  const client = new AirwebTicketLegacyClient({
    signatureKey: nuxtApp.$config.public.hmackey,
    url: nuxtApp.$config.public.ticketApiUrl,
  });

  if (process.server) {
    const network = useNetwork();
    const headers = useRequestHeaders();

    try {
      network.config = await client.networks.getNetworkConfiguration(headers.host!);
      const formConfig = await client.networks.getSchoolConfiguration(network.config.id);
      const formAPI = new URL(
        `/forms/${formConfig.tunnel_form_code}/configuration`,
        nuxtApp.$config.public.formApiUrl,
      );

      network.formConfig = await $fetch<Form>(formAPI.toString());
    } catch (error) {
      console.error('Error while fetching configuration', error);
    }

    const airwebToken = useCookie(sessionCookieName, sessionCookieConfig);

    if (airwebToken.value) {
      try {
        const user = await client.account.getUser(airwebToken.value);
        const profiles = await client.account.getProfiles(airwebToken.value);

        const waiting: Promise<IAirwebTicketLegacyUserInfo>[] = [];

        for (const profile of profiles) {
          if (profile.isParent) continue;
          const promise = client.account.getUser(airwebToken.value, profile.userId);
          waiting.push(promise);
        }

        const auth = useAuth();
        auth.user = user;
        auth.profiles = await Promise.all(waiting);
      } catch (error) {
        airwebToken.value = null;
      }
    }
  }

  return {
    provide: {
      ticket: client,
    },
  };
});
