import { ofetch } from 'ofetch';

export default defineNuxtPlugin((nuxtApp) => {
  const key = nuxtApp.$config.public.firebaseApiKey;

  const firebaseClient = ofetch.create({
    baseURL: `https://identitytoolkit.googleapis.com/v1/`,
    params: { key },
  });

  const sdk = {
    login(body: { email: string; password: string }) {
      interface SignIn {
        kind: string;
        localId: string;
        email: string;
        displayName: string;
        idToken: string;
        registered: boolean;
        refreshToken: string;
        expiresIn: string;
      }

      return firebaseClient<SignIn>(`/accounts:signInWithPassword`, {
        method: 'POST',
        body: { ...body, returnSecureToken: true },
      });
    },
    signUp(body: { email: string; password: string; firstname: string; lastname: string }) {
      interface SignUp {
        kind: string;
        idToken: string;
        displayName: string;
        email: string;
        refreshToken: string;
        expiresIn: string;
        localId: string;
      }

      return firebaseClient<SignUp>(`/accounts:signUp`, {
        method: 'POST',
        body: {
          email: body.email,
          password: body.password,
          displayName: `${body.firstname} ${body.lastname}`,
        },
      });
    },
    updatePassword(body: { idToken: string; password: string }) {
      interface feedbackSetAccountInfoResponse {
        // kind: string;
        localId: string;
        // email: string;
        // displayName: string;
        idToken: string;
        providerUserInfo: ProviderUserInfo;
        newEmail: string;
        // photoUrl: string;
        refreshToken: string;
        expiresIn: string;
        // passwordHash: string;
        emailVerified: boolean;
      }

      interface ProviderUserInfo {
        providerId: string;
        displayName: string;
        photoUrl: string;
        federatedId: string;
        email: string;
        rawId: string;
        screenName: string;
        phoneNumber: string;
      }

      return firebaseClient<feedbackSetAccountInfoResponse>(`/accounts:update`, {
        method: 'POST',
        body,
      });
    },
  };

  return {
    provide: {
      firebase: sdk,
    },
  };
});
