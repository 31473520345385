<script lang="ts" setup>
interface Props {
  title?: string;
  content?: string;
  button?: string;
  success?: boolean;
  error?: boolean;
  warning?: boolean;
  noFooter?: boolean;
  bgColor?: string;
  classList?: string;
  fullScreen?: boolean;
  hasMaxHeight?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  content: '',
  button: '',
  success: false,
  error: false,
  warning: false,
  noFooter: false,
  bgColor: 'bg-neutral-100',
  classList: '',
  fullScreen: true,
  hasMaxHeight: true,
});

const emit = defineEmits(['close']);

onMounted(() => {
  document.body.style.overflow = 'hidden';
});

onUnmounted(() => {
  document.body.removeAttribute('style');
});
</script>

<template>
  <Teleport to="#modal">
    <div class="fixed left-0 top-0 z-50 flex h-full w-screen items-center justify-center px-4">
      <AppBackdrop class="absolute z-40" @close="() => emit('close')" />

      <div
        :class="[
          bgColor,
          classList,
          {
            'border-red-500': props.error,
            'border-green-500': props.success,
            'border-yellow-500': props.warning,
            'max-w-lg': !props.fullScreen,
          },
        ]"
        class="container relative z-50 mx-auto min-w-[250px] rounded border-2 border-transparent p-4 sm:p-6"
      >
        <header>
          <slot name="header">
            <p class="text-2xl font-semibold uppercase text-black">
              {{ props.title }}
            </p>
          </slot>
        </header>

        <main
          class="mt-4 overflow-auto px-1"
          :class="{
            'max-h-[65vh]': $props.hasMaxHeight,
          }"
        >
          <slot name="content">
            <div
              class="modal-content whitespace-pre-line text-neutral-800"
              v-html="props.content"
            />
          </slot>
        </main>

        <footer v-if="!noFooter" class="mt-4">
          <slot name="footer">
            <AppButton @click="() => emit('close')">{{ props.button }}</AppButton>
          </slot>
        </footer>
      </div>
    </div>
  </Teleport>
</template>

<!-- Need to keep this css because it change the v-html content -->
<style lang="postcss">
.modal-content h2 {
  @apply mt-4 text-lg font-semibold uppercase;
}

.modal-content p {
  @apply mt-4;
}

.modal-content a {
  @apply text-blue-600 underline;
}
</style>
