<script lang="ts" setup>
import { version } from '@/package.json';
import { useNetwork } from '@/store/network';

const network = useNetwork();
const nuxtApp = useNuxtApp();
const localePath = useLocalePath();
const switchLocalePath = useSwitchLocalePath();

const date = new Date().getFullYear();

const address = computed(() => {
  if (!network.config?.registered_address) return '';

  const [city, cp, address2, address1, ...rest] = network.config.registered_address
    .split('<br/>')
    .reverse();
  return [address1, address2, ...rest, `${cp} - ${city}`].join(`<br/>`);
});
</script>

<template>
  <footer class="relative z-10 bg-neutral-800 text-neutral-100">
    <div
      class="container grid space-y-4 p-6 pt-12 text-center sm:grid-cols-3 sm:flex-row sm:justify-between sm:space-y-0 sm:text-left"
    >
      <!-- left side -->
      <div class="order-1">
        <p class="text-lg font-semibold">
          {{ network.config.name }}
        </p>
        <p class="mt-3 text-neutral-300" v-html="address"></p>
      </div>

      <div class="order-2 flex flex-col items-center space-y-3">
        <NuxtLink :to="localePath('legals')" class="text-neutral-200 underline">
          {{ $t('pages.footer.legal_notice') }}
        </NuxtLink>
      </div>

      <ul
        class="order-3 flex flex-col items-center justify-start space-y-3 font-semibold sm:mb-auto sm:mt-0"
      >
        <li v-if="network.config.phone">
          <a
            :href="`tel:${network.config.phone}`"
            class="flex items-center space-x-3 text-neutral-200 hover:underline"
          >
            <Icon name="mdi:phone-outline" class="h-6" />
            <span>{{ network.config.phone }}</span>
          </a>
        </li>

        <!-- Email adress contact me link -->
        <li v-if="network.config.contact_email">
          <a
            :href="`mailto:${network.config.contact_email}`"
            target="_blank"
            rel="noopener"
            class="flex items-center space-x-3 text-neutral-200 hover:underline"
          >
            <Icon name="mdi:email-outline" class="h-6" />
            <span>{{ $t('pages.footer.contact') }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="bg-neutral-900">
      <div class="container grid gap-2 px-6 py-4 sm:grid-cols-3 sm:gap-0">
        <!-- Language selector -->
        <div
          v-if="network.languageCodeList.length > 1"
          class="flex justify-center divide-x self-center text-xs font-semibold uppercase leading-tight sm:justify-start"
        >
          <NuxtLink
            v-for="(language, index) of network.languageCodeList"
            :key="index"
            :to="switchLocalePath(language)"
            class="px-2 hover:opacity-100"
            :class="[nuxtApp.$i18n.locale.value === language ? 'opacity-100' : 'opacity-75']"
          >
            {{ $t(`otherslabels.${language}`) }}
          </NuxtLink>
        </div>

        <!-- Airweb website link -->
        <p class="mx-auto self-center text-sm">
          <span>{{ $t('pages.footer.powered_by') }}&nbsp;</span>
          <a
            href="https://airweb.fr"
            target="_blank"
            rel="noopener"
            class="order-3 mt-4 underline sm:order-2 sm:mt-0"
          >
            <span class="sr-only">{{ $t('pages.footer.logo_airweb_sr') }}</span>
            <span>airweb</span>
          </a>
          <span> &ndash; {{ date }}</span>
        </p>

        <p
          class="self-center text-center text-xs font-semibold uppercase text-neutral-100 sm:text-right"
        >
          {{ $t('pages.footer.version', { version }) }}
        </p>
      </div>
    </div>
  </footer>
</template>
