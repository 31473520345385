import { get } from 'idb-keyval';

import {
  IAirwebTicketLegacyItemDocument,
  IAirwebTicketLegacyUserInfo,
} from '@airweb/ticket-legacy-client';
import type { TODO } from '@/types/todo';
import { UserDocuments } from './renew';
import { ProductItem } from '~/types/product';

export const useSchool = defineStore('school', {
  state: () => ({
    articleId: null as null | string,
    currentChild: null as null | Partial<IAirwebTicketLegacyUserInfo>,
    product: null as null | Partial<ProductItem>,
    dynamicForm: {} as DynamicForm,
    documents: {} as UserDocuments,
    childInformations: {
      userPicture: null as null | string,
      userPictureName: '',
      userPictureType: '',
      userLastname: '',
      userName: '',
      userMail: '',
      userPhoneNumber: '',
      userBirthday: '',
    },
    hydrationCompleted: true,
  }),
  getters: {
    hasDocuments: (state) => {
      return Boolean(state.product?.documents?.length);
    },
    requiredDocumentsByProduct: (state): IAirwebTicketLegacyItemDocument[] => {
      return state.product?.documents ?? [];
    },
  },

  actions: {
    setChildInfo(profile: TODO): void {
      this.childInformations = profile;
    },
    updateDocuments(documents?: UserDocuments): void {
      this.documents = documents ?? {};
    },
    updateMetadatas(metadatas?: DynamicForm): void {
      this.dynamicForm = metadatas ?? {};
    },
    updateProduct(product?: Partial<ProductItem>): void {
      this.product = product ?? null;
    },
  },

  async hydrate(state) {
    state.hydrationCompleted = false;
    const rawSchoolFromState =
      (await get<string | typeof state>('school').catch(() => '{}')) ?? '{}';
    let schoolFromState: typeof state | null = null;

    // Check if in the indexdb we still have an object (old version)
    // otherwise we've upgraded to the new stringify version
    if (typeof rawSchoolFromState === 'object') {
      schoolFromState = rawSchoolFromState;
    } else {
      schoolFromState = JSON.parse(rawSchoolFromState);
    }

    state.currentChild = schoolFromState?.currentChild ?? null;
    state.product = schoolFromState?.product ?? null;
    state.dynamicForm = schoolFromState?.dynamicForm ?? {};
    state.childInformations = schoolFromState?.childInformations ?? {
      userPicture: null,
      userPictureName: '',
      userPictureType: '',
      userLastname: '',
      userName: '',
      userMail: '',
      userPhoneNumber: '',
      userBirthday: '',
    };
    state.documents = schoolFromState?.documents ?? {};

    state.hydrationCompleted = true;
  },
});

export type DynamicForm = Record<string, string | boolean>;
