import {
  IAirwebTicketLegacyItem,
  IAirwebTicketLegacyOrderArticleRequest,
  IAirwebTicketLegacyOrderShippingRequest,
  IAirwebTicketLegacyOrderVerifyDiscountInfo,
  IAirwebTicketLegacyOrderVerifyRequest,
  IAirwebTicketLegacyUserInfo,
} from '@airweb/ticket-legacy-client';
import { get } from 'idb-keyval';

import { useAuth } from './auth';
import type { TODO } from '@/types/todo';
import { useNetwork } from './network';
import { ShopItem } from '@/interfaces/product.interface';
import { DynamicForm } from './school';
import { UserDocuments } from './renew';
import { DEFAULT_DELIVERY } from '~/utils/supports';
import type { Base64File } from '~/types/file';

export type CartItemType = 'NEW' | 'RENEW' | 'DUPLICATE';

export interface CartItem {
  id: string;
  type: CartItemType;
  product: Pick<
    IAirwebTicketLegacyItem,
    | 'shopItemId'
    | 'shopItemName'
    | 'shopItemDescription'
    | 'shopItemImage'
    | 'shopItemCoef'
    | 'shopItemPrice'
    | 'shopItemPromoPrice'
    | 'shopItemVAT'
    | 'infoText'
    | 'maxBuyAtOnce'
    | 'documents'
    | 'products'
    | 'fields'
    | 'installments'
    | 'isRenewable'
    | 'paymentPreauthorization'
  >;
  profile: Partial<IAirwebTicketLegacyUserInfo> | null;
  delivery?: string;
  data?: {
    picture?: string | null;
    pictureName: string;
    pictureType: string;
    answers?: DynamicForm;
    creationData?: Partial<IAirwebTicketLegacyUserInfo>;
    documents?: Record<string, Base64File | string | null>;
  };
  fields?: Record<string, string>;
}

const defaultAccount = () => ({
  userLastname: '',
  userName: '',
  userBirthday: '',
  address: {
    addressRoute: '',
    addressPostalCode: '',
    addressCity: '',
    addressState: '',
  },
  userPhoneNumber: '',
  userMail: '',
  confirmUserMail: '',
  picture: null,
  password: '',
  confirm: '',
});

export const useCart = defineStore('cart', {
  state: () => ({
    items: [] as CartItem[],
    termsAccepted: false,
    paymentMethod: 'CARD' as 'CARD' | 'TRANSFER',
    delivery: {
      addressRoute: '',
      addressPostalCode: '',
      addressCity: '',
      addressState: '',
    },
    vouchers: [] as string[],
    discounts: [] as IAirwebTicketLegacyOrderVerifyDiscountInfo[],
    taxFreeAmount: null as number | null,
    totalAmount: null as number | null,
    account: defaultAccount(),
    isOrderCompleted: false,
    canAddVoucher: false,
    hydrationCompleted: true,
  }),
  getters: {
    isEmpty(): boolean {
      return this.items.length === 0;
    },
    itemsCount(): number {
      return this.items.length;
    },
    isFree(): boolean {
      // TODO: Implement
      // return this.items.every(item => item.product.shopItemPromoPrice === '0.00');
      return false;
    },
    products(): Partial<IAirwebTicketLegacyItem>[] {
      return this.items.map((item) => item.product);
    },
    totalCart(state) {
      const total = state.items.reduce((total, item) => {
        const productPrice = item.product.shopItemPromoPrice || '0.0';
        return total + parseFloat(productPrice);
      }, 0);

      return total.toString();
    },
    installments(state): number[] {
      const network = useNetwork();

      if (!network.config.multiple_payment) {
        return [1];
      }

      // STEP2: Get all available installments from network based on total cart
      const networkInstallments = (network.config.multiple_payment_installments ?? [])
        .filter((installment) => installment.amount <= parseInt(this.totalCart, 10))
        .map(({ installments }) => installments);

      // STEP2: Compute all installments for every items in the cart
      const itemsInstallements = state.items.reduce<number[]>((installments, item) => {
        if (!item.product.installments) {
          return installments;
        }

        return [...installments, ...item.product.installments];
      }, networkInstallments);

      // STEP3: Make an intersection of all the possible values from the cart and the ones from the networkConfig
      // This makes an INNER JOIN
      const intersectionInstallments = networkInstallments
        .filter((value) => itemsInstallements.includes(value))
        .concat(itemsInstallements)
        .sort((a, b) => a - b);

      // STEP4: Filter out progressively the installments not supported by all product
      const filteredInstallments = state.items.reduce<number[]>((installments, item) => {
        return installments.filter((installment) => {
          return (item.product?.installments ?? []).includes(installment);
        });
      }, intersectionInstallments);

      return Array.from(new Set(filteredInstallments));
    },
    isAccountCreation(state): boolean {
      return Boolean(
        state.account &&
          state.account.userLastname &&
          state.account.userName &&
          state.account.userBirthday &&
          state.account.address.addressRoute &&
          state.account.address.addressPostalCode &&
          state.account.address.addressCity &&
          state.account.address.addressState &&
          state.account.userPhoneNumber &&
          state.account.userMail &&
          state.account.password &&
          state.account.confirm,
      );
    },
    validVouchers(state): string[] {
      return state.vouchers.filter((voucher) =>
        this.discounts.find((discount) => discount.sourceCode === voucher && !discount.error),
      );
    },
    invalidDiscounts(state): IAirwebTicketLegacyOrderVerifyDiscountInfo[] {
      return state.discounts.filter((discount) => discount.error);
    },
    validDiscounts(state): IAirwebTicketLegacyOrderVerifyDiscountInfo[] {
      return state.discounts.filter((discount) => !discount.error);
    },
    hasShippingDeliveryItem(state): boolean {
      return state.items.some(({ delivery }) => delivery === 'SHIPPING');
    },
    hasPreauthorizedProduct(state): boolean {
      return state.items.some((item) => {
        return item.product.paymentPreauthorization;
      });
    },
  },
  actions: {
    async updateOrderArticleProfiles() {
      const auth = useAuth();

      for (const article of this.items) {
        const needProfileCreation =
          article.data &&
          article.data?.creationData &&
          article.data.creationData.userLastname &&
          article.data.creationData.userName;

        const picture = await this.getArticlePictureFile(article);
        if (needProfileCreation) {
          const profile = await auth.createProfile({
            picture: picture,
            answers: article.data?.answers,
            creationData: article.data?.creationData,
          });

          if (!profile) continue;

          article.profile = profile;

          // Avoid multiple profile creation
          if (article.profile && article.data) article.data.creationData = undefined;
        }

        if (!needProfileCreation && article.data?.picture && article.profile?.userId && picture) {
          auth.uploadAvatar(article.profile.userId.toString(), picture);
        }

        article.profile = await this.updateProfile(
          article!.profile!.userId!.toString(),
          {
            ...(article.data?.creationData?.userBirthday && {
              userBirthday: article.data.creationData.userBirthday,
            }),
            ...(article.data?.creationData?.userMail && {
              userMail: article.data.creationData.userMail,
            }),
            ...(article.data?.creationData?.userPhoneNumber && {
              userPhoneNumber: article.data.creationData.userPhoneNumber,
            }),
          },
          article!.data?.answers ?? {},
        );

        if (article.data?.documents) {
          const submission = await this.uploadDocuments({
            product: article.product,
            documents: article.data.documents,
            user: {
              userToken: auth.user!.userToken,
              userId: article.profile.userId!.toString(),
            },
          });

          article.data.documents = submission.documentIds;
        }
      }
    },
    createOrderArticles() {
      const articles: IAirwebTicketLegacyOrderArticleRequest[] = [];

      for (const article of this.items) {
        const isSubscription = article.product.isRenewable && this.paymentMethod === 'TRANSFER';

        const articleData: IAirwebTicketLegacyOrderArticleRequest = {
          quantity: 1, // article.quantity
          productId: article.product.shopItemId!,
          customerId: +article!.profile!.userId!,
          support: article.delivery ?? DEFAULT_DELIVERY,
          isSubscription,
        };

        if (article.fields && Object.keys(article.fields).length > 0) {
          articleData.fields = article.fields;
        }

        articles.push(articleData);
      }

      return articles;
    },
    async uploadDocuments({
      product,
      documents,
      user,
    }: {
      product: Partial<ShopItem>;
      documents: UserDocuments;
      user: {
        userToken: string;
        userId: string;
      };
    }): Promise<TODO> {
      console.log('Documents to upload', documents);

      const nuxtApp = useNuxtApp();

      // STEP1: Upload every document sequentially
      const documentIds: string[] = [];
      const articleDocumentIds: Record<string, string> = {};

      const uploadDocumentsErrorAlreadyValid: Array<{
        code: number;
        message: string;
        documentType: string;
      }> = [];

      for (const [documentType, document] of Object.entries(documents)) {
        if (!document) {
          continue;
        }

        // If document is already uploaded, we have the id of the document
        if (typeof document === 'string') {
          documentIds.push(document);
          articleDocumentIds[documentType] = document;
          continue;
        }

        try {
          // Otherwise this is the base 64 of the document, so we need to transform it to a file
          const documentFile = await base64ToFile(
            document.dataUrl,
            document.fileName,
            document.fileType,
          );

          const uploadedDocument = await nuxtApp.$ticket.document.uploadDocument(
            user.userToken,
            user.userId,
            documentType,
            documentFile,
          );

          const uploadedDocumentId = uploadedDocument.document_id.toString();

          documentIds.push(uploadedDocumentId);
          articleDocumentIds[documentType] = uploadedDocumentId;
        } catch (error: any) {
          nuxtApp.$sentryCaptureException(error);

          // Code 4 is for "Document already valid"
          if (
            error?.response?.data?.error &&
            error.response.data.error?.code &&
            +error.response.data.error.code === 4
          ) {
            uploadDocumentsErrorAlreadyValid.push({
              ...error.response.data.error,
              documentType,
            });
          }
          // This catch is useful to prevent already uploaded document error
          // not entirely sure where that one is coming from
          continue;
        }
      }

      const network = useNetwork();

      let existingDocumentIds: string[] = [];

      // Hange the case where the uploaded document is already valid
      // So we try to retrieve it in the user documents
      if (uploadDocumentsErrorAlreadyValid.length > 0) {
        const userDocuments = await nuxtApp.$ticket.document.getUserDocuments(user.userToken, {
          userId: user.userId,
          networkId: network.config.id,
        });

        existingDocumentIds = uploadDocumentsErrorAlreadyValid.reduce<string[]>(
          (docIdList, docError) => {
            const doc = userDocuments.find(
              (userDoc) => +userDoc.document_type_id === +docError.documentType,
            );

            if (doc) {
              docIdList.push(doc.document_id);
            }

            return docIdList;
          },
          [],
        );
      }

      const submissionId = await nuxtApp.$ticket.document.createDocumentsSubmission(
        user.userToken,
        product.shopItemId!,
        [...documentIds, ...existingDocumentIds],
        {
          userId: user.userId,
        },
      );

      return {
        submissionId,
        documentIds: articleDocumentIds,
      };
    },
    async updateProfile(userId: string, data: TODO, answers: DynamicForm) {
      const auth = useAuth();
      const nuxtApp = useNuxtApp();
      const network = useNetwork();

      type Metadata = { key: string; value: TODO; network_id: string | null };
      const metadatas: Metadata[] = [];

      Object.keys(answers).forEach((key) => {
        metadatas.push({
          key,
          value: answers[key],
          network_id: network.config.id,
        });
      });

      type Data = Parameters<typeof nuxtApp.$ticket.account.update>[2];

      const payload: Data = {};

      if (data.userBirthday) {
        payload.userBirthday = data.userBirthday;
      }

      if (metadatas.length > 0) {
        payload.metadatas = metadatas;
      }

      return await nuxtApp.$ticket.account.update(auth.user?.userToken!, userId, payload, {
        networkId: network.config.id,
      });
    },
    async verifyOrder() {
      const auth = useAuth();
      const nuxtApp = useNuxtApp();
      const network = useNetwork();

      const orderArticles = this.createOrderArticles();

      try {
        // TODO: update SDK (later, wait for the back team to update the docs)
        const body: IAirwebTicketLegacyOrderVerifyRequest & {
          shipping?: IAirwebTicketLegacyOrderShippingRequest;
        } = {
          networkId: network.config.id,
          articles: orderArticles,
          vouchers: this.vouchers.length ? this.vouchers : undefined,
        };

        if (this.hasShippingDeliveryItem) {
          body.shipping = {
            name: auth.fullname,
            city: this.delivery.addressCity,
            line1: this.delivery.addressRoute,
            line2: '',
            line3: '',
            state: this.delivery.addressState,
            zip: this.delivery.addressPostalCode,
          };
        }

        const order = await nuxtApp.$ticket.orders.verify(auth.user!.userToken, body);

        this.taxFreeAmount = order.taxFreeAmount;
        this.totalAmount = order.totalAmount;
        this.discounts = order.discounts?.length ? order.discounts : [];

        if (this.discounts.length) {
          const updatedVouchers = [];

          for (const discount of this.discounts) {
            if (!discount.error && discount.value) {
              updatedVouchers.push(discount.sourceCode);
            }
          }

          this.vouchers = updatedVouchers;
        } else {
          if (this.vouchers.length) {
            this.vouchers = [];
            this.discounts.push({
              sourceType: '',
              sourceCode: 'VOUCHER_NOT_FOUND',
              error: 'VOUCHER_NOT_FOUND',
            });
          }
        }
      } catch (error) {
        nuxtApp.$sentryCaptureException(error);
        console.log(error);

        this.vouchers = [];
        this.discounts.push({
          sourceType: '',
          sourceCode: 'VOUCHER_NOT_FOUND',
          error: 'VOUCHER_NOT_FOUND',
        });
      }
    },
    async removeVoucher(index: number): Promise<void> {
      this.vouchers.splice(index, 1);

      await this.verifyOrder();
    },
    resetAccount() {
      this.account = defaultAccount();
    },
    async getArticlePictureFile(article: CartItem): Promise<File | null> {
      if (article.data?.picture && article.data?.pictureName && article.data?.pictureType) {
        return base64ToFile(
          article.data?.picture,
          article.data?.pictureName,
          article.data?.pictureType,
        );
      }

      return null;
    },
  },
  async hydrate(state) {
    state.hydrationCompleted = false;
    const rawCartFromState = (await get<string | typeof state>('cart').catch(() => '{}')) ?? '{}';
    let cartFromState: typeof state | null = null;

    // Check if in the indexdb we still have an object (old version)
    // otherwise we've upgraded to the new stringify version
    if (typeof rawCartFromState === 'object') {
      cartFromState = rawCartFromState;
    } else {
      cartFromState = JSON.parse(rawCartFromState);
    }

    // Cleanup store in case something went wrong
    if (cartFromState?.items) {
      state.items = cartFromState.items.filter((item) => item.product !== null);
    }

    state.termsAccepted = cartFromState?.termsAccepted ?? false;
    state.delivery = cartFromState?.delivery ?? {
      addressRoute: '',
      addressPostalCode: '',
      addressCity: '',
      addressState: '',
    };
    state.paymentMethod = cartFromState?.paymentMethod ?? 'CARD';
    state.account = cartFromState?.account ?? defaultAccount();
    state.hydrationCompleted = true;
  },
});
