<script lang="ts" setup>
import { RouterLink } from 'vue-router';

interface Props {
  type?: string;
  to?: string | object;
  asLabel?: boolean;
  loading?: boolean;
  href?: string;
  outline?: boolean;
  state?: 'primary' | 'secondary' | 'danger' | 'warning' | 'success' | 'info' | 'base';
}

const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  to: '',
  asLabel: false,
  loading: false,
  href: '',
  state: 'primary',
  outline: false,
});

const emit = defineEmits(['click']);

const btnClasses = computed(() => {
  const baseClasses = [
    'disabled:cursor-not-allowed',
    'disabled:opacity-50',
    'inline-flex',
    'py-2',
    'text-sm',
    'font-semibold',
    'tracking-wide',
    'uppercase',
    'rounded',
    'cursor-pointer',
  ];

  if (props.state !== 'base') baseClasses.push('shadow');

  if (props.outline) {
    baseClasses.push('border', 'hover:text-neutral-100');

    switch (props.state) {
      case 'base':
        break;
      case 'secondary':
        baseClasses.push(
          'px-5',
          'text-secondary',
          'border-secondary',
          'hover:bg-secondary',
          'hover:text-secondary-contrast',
        );
        break;
      case 'danger':
        baseClasses.push(
          'px-5',
          'text-red-500',
          'border-red-500',
          'hover:bg-red-500',
          'active:bg-red-600',
        );
        break;
      case 'info':
        baseClasses.push(
          'px-5',
          'text-blue-500',
          'border-blue-500',
          'hover:bg-blue-500',
          'active:bg-blue-600',
        );
        break;
      case 'warning':
        baseClasses.push(
          'px-5',
          'text-orange-500',
          'border-orange-500',
          'hover:bg-warning-500',
          'active:bg-warning-600',
        );
        break;
      case 'success':
        baseClasses.push(
          'px-5',
          'text-green-500',
          'border-green-500',
          'hover:bg-green-500',
          'active:bg-green-600',
        );
        break;
      case 'primary':
        baseClasses.push(
          'px-5',
          'text-primary',
          'border-primary',
          'hover:bg-primary',
          'hover:text-primary-contrast',
        );
        break;
      default:
        baseClasses.push(
          'px-5',
          'text-neutral-800',
          'border-neutral-800',
          'hover:bg-neutral-800',
          'active:bg-neutral-900',
        );
        break;
    }
  } else {
    switch (props.state) {
      case 'base':
        break;
      case 'secondary':
        baseClasses.push(
          'px-5',
          'bg-secondary',
          'text-secondary-contrast',
          'hover:bg-secondary',
          'active:bg-secondary',
        );
        break;
      case 'danger':
        baseClasses.push(
          'px-5',
          'bg-red-500',
          'text-neutral-100',
          'hover:bg-red-600',
          'active:bg-red-700',
        );
        break;
      case 'info':
        baseClasses.push(
          'px-5',
          'bg-blue-500',
          'text-neutral-100',
          'hover:bg-blue-600',
          'active:bg-blue-700',
        );
        break;
      case 'warning':
        baseClasses.push(
          'px-5',
          'bg-warning-500',
          'text-neutral-100',
          'hover:bg-warning-600',
          'active:bg-warning-700',
        );
        break;
      case 'success':
        baseClasses.push(
          'px-5',
          'bg-green-500',
          'text-neutral-100',
          'hover:bg-green-600',
          'active:bg-green-700',
        );
        break;
      case 'primary':
        baseClasses.push(
          'px-5',
          'bg-primary',
          'text-primary-contrast',
          'hover:bg-primary',
          'active:bg-primary',
        );
        break;
      default:
        baseClasses.push(
          'px-5',
          'bg-neutral-800',
          'text-neutral-100',
          'hover:bg-neutral-900',
          'active:bg-neutral-black',
        );
        break;
    }
  }

  return baseClasses;
});
</script>

<template>
  <NuxtLink
    v-if="props.to || props.href"
    :class="btnClasses"
    :to="props.to || props.href"
    v-bind="{ ...$attrs }"
  >
    <slot />
  </NuxtLink>

  <component
    v-else
    :is="props.asLabel ? 'label' : 'button'"
    :type="props.asLabel ? undefined : props.type"
    :class="btnClasses"
    v-bind="{ ...$attrs }"
    @click="props.asLabel ? undefined : emit('click')"
  >
    <slot />

    <InlineLoader v-if="props.loading" class="ml-4 h-6 w-6" />
  </component>
</template>
